<template>
<v-ons-page id="addcontact" v-if="showAddModal">

<v-ons-list >
 
  <v-ons-list-header>
   <v-ons-list-item>
      <div class="left">
       <ons-button :disabled="(!(((newcontact.data.FirstName1 && newcontact.data.LastName1) ||
          (newcontact.data.FirstName2 && newcontact.data.LastName2 ) ||
          (newcontact.data.Company)) && (newcontact.database))) ">
          <v-ons-icon  icon="md-save" class="list-item__icon" @click="saveNew()"></v-ons-icon></ons-button> 
      </div>
      <div class="right">
        <ons-button >
          <v-ons-icon  icon="md-close" class="list-item__icon" @click="hide" ></v-ons-icon> </ons-button>
      </div>
   </v-ons-list-item>
      </v-ons-list-header>
          <v-ons-list-header>Contact Information</v-ons-list-header>
          <v-ons-list-item style="width:100%">
            <div class="center">  
                Database:  <v-ons-select style="width:70%"  placeholder="Database" v-model="newcontact.database">
                 <option v-for="item in optiondb"  :key="item.text" :value="item.text"> {{ item.text }}</option>
              </v-ons-select>
            </div>
          </v-ons-list-item> 
          <v-ons-list-item>
              <v-ons-select class="editmr" placeholder="Title (1)" v-model="newcontact.data.Title1">
                <option value=""></option>
                <option value="Mr">Mr</option>
                <option value="Ms">Ms</option>
                <option value="Mrs">Mrs</option>
              </v-ons-select>
              <v-ons-input class="editname" placeholder="First Name (1)" float v-model="newcontact.data.FirstName1"/>
              <v-ons-input class="editname" placeholder="Last Name (1)" float v-model="newcontact.data.LastName1"/> 
          </v-ons-list-item>

          <v-ons-list-item>
              <v-ons-select class="editmr" placeholder="Title (2)" v-model="newcontact.data.Title2">
                <option value=""></option>
                <option value="Mr">Mr</option>
                <option value="Ms">Ms</option>
                <option value="Mrs">Mrs</option>
              </v-ons-select>
              <v-ons-input class="editname" placeholder="First Name (2)" float v-model="newcontact.data.FirstName2"/>
              <v-ons-input class="editname" placeholder="Last Name (2)" float v-model="newcontact.data.LastName2"/> 
          </v-ons-list-item>
           <v-ons-list-item>
              <v-ons-input class="editrow" placeholder="Company" float v-model="newcontact.data.Company"/> 
              
          </v-ons-list-item>
           <v-ons-list-item>
              <v-ons-input class="editrow" placeholder="Email" float v-model="newcontact.data.Email"/> 
          </v-ons-list-item>
          <v-ons-list-item>
              <v-ons-input class="edithalfrow" placeholder="Telephone (1)" float v-model="newcontact.data.Telephone1"/> 
              <v-ons-input class="edithalfrow" placeholder="Telephone (2)" float v-model="newcontact.data.Telephone2"/> 
           </v-ons-list-item>
          <v-ons-list-item>
              <v-ons-input class="edithalfrow" placeholder="Telephone (3)" float v-model="newcontact.data.Telephone3"/> 
              <v-ons-input class="edithalfrow" placeholder="Telephone (4)" float v-model="newcontact.data.Telephone4"/> 
          </v-ons-list-item>
           <v-ons-list-item>
              <v-ons-input class="editrow" placeholder="Telephone (5)" float v-model="newcontact.data.Telephone5"/> 
          </v-ons-list-item>
   
          <v-ons-list-header>Contact Address</v-ons-list-header>
          
          <v-ons-list-item>
              <v-ons-input class="editrow" placeholder="Address (1)" float v-model="newcontact.data.Street1"/>
          </v-ons-list-item>
          <v-ons-list-item>
              <v-ons-input class="editrow" placeholder="Address (2)" float v-model="newcontact.data.Street2"/>
          </v-ons-list-item>
          <v-ons-list-item>
              <v-ons-input class="editrow" placeholder="Address (3)" float v-model="newcontact.data.Street3"/>
          </v-ons-list-item>
         
          <v-ons-list-item>
              <v-ons-input class="edithalfrow" placeholder="City" float v-model="newcontact.data.City"/> 
              <v-ons-input class="edithalfrow" placeholder="Postal Code" float v-model="newcontact.data.Zip"/> 
          </v-ons-list-item>
          <v-ons-list-item>
              <v-ons-input class="edithalfrow" placeholder="Province" float v-model="newcontact.data.State"/> 
              <v-ons-input class="edithalfrow" placeholder="Country" float v-model="newcontact.data.Country"/>
          </v-ons-list-item>
 
       
         
        <v-ons-list-header> Additional Information  
         
          </v-ons-list-header>
          <v-ons-list-item>
              <div class="fulldiv">
              <v-ons-select placeholder="ContactID" class="editrow" v-model="selectedcontactid">
                 <option v-for="item in optioncontactid"  :key="item.id" :value="item.value"> {{ item.text }}</option>
              </v-ons-select>
              </div>
              <div class="fulldiv" v-show="(selectedcontactidvisible)"> 
               <v-ons-input class="editoption" v-model="selectedcontactid" placeholder="Enter ContactID" type="text" /><v-ons-button @click="selectedcontactidvisible=false">OK</v-ons-button>
              </div>
          </v-ons-list-item>

          <v-ons-list-item>
              <div class="fulldiv">
               <v-ons-select placeholder="Market Position" class="editrow" v-model="selectedmarkposi">
                 <option v-for="item in optionmarkposi"  :key="item.id" :value="item.value"> {{ item.text}}</option>
              </v-ons-select>
              </div>
              <div class="fulldiv" v-show="(selectedmarkposivisible)"> 
               <v-ons-input class="editoption" v-model="selectedmarkposi" placeholder="Enter Market Position" type="text" /><v-ons-button @click="selectedmarkposivisible=false">OK</v-ons-button>
              </div>

          </v-ons-list-item>
          <v-ons-list-item>
              <div class="fulldiv">
               <v-ons-select placeholder="Relationship" class="editrow" v-model="selectedrltn">
                 <option v-for="item in optionrltn"  :key="item.id" :value="item.value"> {{ item.text }}</option>
              </v-ons-select>
              </div>
              <div class="fulldiv" v-show="(selectedrltnvisible)"> 
               <v-ons-input class="editoption" v-model="selectedrltn" placeholder="Enter Relationship" type="text" /><v-ons-button @click="selectedrltnvisible=false">OK</v-ons-button>
              </div>
          </v-ons-list-item>

          <v-ons-list-item>
              <div class="fulldiv">
              <v-ons-select placeholder="Investment Range" class="editrow" v-model="selectedivstrng">
                 <option v-for="item in optionivstrng"  :key="item.id" :value="item.value"> {{ item.text }}</option>
              </v-ons-select>
              </div>
              <div class="fulldiv" v-show="(selectedivstrngvisible)"> 
               <v-ons-input class="editoption" v-model="selectedivstrng" placeholder="Enter Investment Type" type="text" /><v-ons-button @click="selectedivstrngvisible=false">OK</v-ons-button>
              </div>
          </v-ons-list-item>
          <v-ons-list-item>
              <div class="fulldiv">
                <v-ons-select placeholder="Type" class="editrow" v-model="selectedtype">
                 <option v-for="item in optiontype"  :key="item.id" :value="item.value"> {{ item.text }}</option>
              </v-ons-select>
              </div>
              <div class="fulldiv" v-show="(selectedtypevisible)"> 
               <v-ons-input class="editoption" v-model="selectedtype" placeholder="Enter Type" type="text" /><v-ons-button @click="selectedtypevisible=false">OK</v-ons-button>
              </div>
          </v-ons-list-item>
          <v-ons-list-item>
              <div class="fulldiv">
              <v-ons-select placeholder="Last Result" class="editrow" v-model="selectedlstrst">
                 <option v-for="item in optionlstrst"  :key="item.id" :value="item.value"> {{ item.text }}</option>
              </v-ons-select>
              </div>
              <div class="fulldiv" v-show="(selectedlstrstvisible)"> 
               <v-ons-input class="editoption" v-model="selectedlstrst" placeholder="Enter Last Result" type="text" /><v-ons-button @click="selectedlstrstvisible=false">OK</v-ons-button>
              </div>
          </v-ons-list-item>
          <v-ons-list-item>
              <div class="fulldiv">
              <v-ons-select placeholder="Next Action" class="editrow" v-model="selectednxtact">
                 <option v-for="item in optionnxtact"  :key="item.id" :value="item.value"> {{ item.text }}</option>
              </v-ons-select>
              </div>
              <div class="fulldiv" v-show="(selectednxtactvisible)"> 
               <v-ons-input class="editoption" v-model="selectednxtact" placeholder="Enter Next Action" type="text" /><v-ons-button @click="selectednxtactvisible=false">OK</v-ons-button>
              </div>
          </v-ons-list-item>

         
        </v-ons-list>


 
 

<!-- Toast for Error -->
         <v-ons-toast :visible.sync="errortoastVisible" animation="ascend">
            Error {{ this.error }} 
             <button @click="errortoastVisible = false">Close</button>
         </v-ons-toast>

<!-- Toast for Saved -->
         <v-ons-toast :visible.sync="savedtoastVisible" animation="ascend">
            Contact Saved! 
             <button @click="askforLink($ons)">OK</button>
         </v-ons-toast>
<!-- Toast for Linked -->
           <v-ons-toast :visible.sync="linkedtoastVisible" animation="ascend">
            Property Linked! 
            <button @click="hide">OK</button>
         </v-ons-toast>

<!-- Link Dialog-->
        <v-ons-dialog cancelable :visible.sync="linkdialogVisible">
            <div style="text-align: center; margin-top: 60px; color: #fff;"></div>
                <ons-toolbar>
                <div class="center">Link to property</div>
                <div class="right"><v-ons-icon icon="md-close" class="list-item__icon"  @click="linkdialogVisible=false; hide;"></v-ons-icon></div>
              </ons-toolbar>
              <v-ons-list >
                 <v-ons-list-item>
                 <v-ons-input style="width:96%" :disabled="(pidaddress != null && pidaddress.length > 0)" placeholder="Input PID (11 characters)" float v-model="pid"/> 
                
                </v-ons-list-item>
                 <v-ons-list-item>
                    <v-ons-input :disabled="(pid != null && pid.length > 0)" style="width:96%" placeholder="Property Address" float v-model="pidaddress"/> 
              
                </v-ons-list-item>

                <p style="text-align: center"> 
                     <v-ons-button  :disabled="(pid != null && pid.length!=11) && (pidaddress != null && pidaddress.length==0)" @click="searchpid()">
                     Search
                     
                    </v-ons-button>
              </p>
              </v-ons-list>
              
        </v-ons-dialog>


<!-- property list to link -->
  <v-ons-dialog id="properties-dialog" style="overflow-y: auto;" >  
       <v-ons-list  >
          <div style="height: 80%;overflow-y: scroll;">
         <v-ons-list-item>
            <div class="center">SELECT PROPERTIES TO LINK

            </div>
           <div class="right">
              
              <v-ons-icon icon="md-close" size="15px" class="list-item__icon" onclick="hideDialog('properties-dialog')"> </v-ons-icon>
            </div>

         </v-ons-list-item>
         <v-ons-list-item tappable v-for="(apitem, index) in this.pidpropertyforselect" :key="index">  
              <div class="list-item__title"> 
                <v-ons-checkbox v-model="apitem.checked"></v-ons-checkbox>  
                PID: {{ apitem.PID }} {{ apitem.MorePIDs }} </div>
              <div class="list-item__title">
                {{ apitem.LocationOriginal }}  {{ apitem.City }} {{ apitem.State }} {{ apitem.Zip }} {{ apitem.Country }} </div>
              
              <div class="list-item__title" v-show="(apitem.FirstName1)"> {{ apitem.FirstName1}} {{ apitem.LastName1}}</div>
              <div class="list-item__title" v-show="(apitem.FirstName2)"> {{ apitem.FirstName2}} {{ apitem.LastName2}}</div>
              <div class="list-item__title"  v-show="(apitem.Company)"> {{ apitem.Company}}</div>
                     

         </v-ons-list-item>
           <v-ons-list-item>
         <p style="text-align: center;" >  
           <span><v-ons-button @click="linkpid($ons)">Link</v-ons-button></span>
           
         </p>
           </v-ons-list-item>
          </div>
       </v-ons-list>

  </v-ons-dialog>
</v-ons-page>
</template>
<script>
window.fn = {};

window.showDialog = function(id) {
  
  document
    .getElementById(id)
    .show();
};

window.hideDialog = function(id) {

  document
    .getElementById(id)
    .hide();
};

import router from "../router";
import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import fb from "../firebase";

Vue.use(VueAxios, axios)


export default {
 
  data() {
    return {
      router:router,
      error : null,
      errortoastVisible: false,
      savedtoastVisible: false,
      linkedtoastVisible: false,

      linkdialogVisible :false,
      showAddModal: false,
       
      currentUser:{},
      selectedcontactid : null,
      selectedcontactidvisible : null,
      selectedtype : null,
      selectedtypevisible : null,
      selectedrltn : null,
      selectedrltnvisible : null,
      selectedmarkposi : null,
      selectedmarkposivisible : false,
      selectedivstrng : null,
      selectedivstrngvisible : null,
      selectedlstrst: null,
      selectedlstrstvisible : null,
      selectednxtact: null,
      selectednxtactvisible : null,
      optiondb:[
          {text:'apt'},
          {text:'commercial'},
          {text:'industrial'},
          {text:'subprimary'},
          ],
      // Add more filter options - contact
      optioncontactid:[
        
        {id:1, text:'Developer', value:'Developer'},
        {id:2, text:'Foreign', value:'Foreign'},
        {id:3, text:'Foreign (HK)', value:'Foreign (HK)'},
        {id:4, text:'Foreign (CN)', value:'Foreign (CN)'},
        {id:5, text:'Foreign (KO)', value:'Foreign (KO)'},
        {id:6, text:'Foreign (SG)', value:'Foreign (SG)'},
        {id:7, text:'Foreign (TW)', value:'Foreign (TW)'}, 
        {id:8, text:'Foreign (UK)', value:'Foreign (UK)'},
        {id:9, text:'Foreign (US)', value:'Foreign (US)'},
        {id:10, text:'Foundation', value:'Foundation'},
        {id:11, text:'Government', value:'Government'},
        {id:12, text:'Institution', value:'Institution'},
        {id:13, text:'Non-market', value:'Non-market'},
        {id:14, text:'Private', value:'Private'},
        {id:15, text:'', value:''},
        {id:0, text:'-- Select ContactID --', value:null},
      ],
      // Add more  filter options - type
      optiontype:[
        
        {id:1, text:'1 prop', value:'1 prop'},
        {id:2, text:'2 prop', value:'2 prop'},
        {id:3, text:'active listing', value:'active listing'},
        {id:4, text:'bigboy target', value:'bigboy target'},
        {id:5, text:'black-list', value:'black-list'},
        {id:6, text:'black-list (competitor)', value:'black-list (competitor)'},
        {id:7, text:'black-list (inbound request)', value:'black-list (inbound request)'},
        {id:8, text:'black-list (property manager)', value:'black-list (property manager)'},
        {id:9, text:'Charitable trust', value:'Charitable trust'},
        {id:10, text:'City owned', value:'City owned'},
        {id:11, text:'Co-Op:Common Mailing Address', value:'Co-Op:Common Mailing Address'},
        {id:12, text:'Gocart target area', value:'Gocart target area'},
        {id:13, text:'Government/Institution', value:'Government/Institution'},
        {id:14, text:'Granville Island', value:'Granville Island'},
        {id:15, text:'just sold', value:'just sold'},
        {id:16, text:'Leasehold', value:'Leasehold'},
        {id:17, text:'Non-profit', value:'Non-profit'},
        {id:18, text:'realtor own', value:'realtor own'},
        {id:19, text:'retail', value:'retail'},
        {id:20, text:'SRO', value:'SRO'},
        {id:21, text:'update pending', value:'update pending'},
        {id:22, text:'', value:''},
        {id:0, text:'-- Select Type --', value:null},
       
      ],
      optionrltn:[
        
        {id:1, text:'1 (hang-out)', value:'1 (hang-out)'},
        {id:2, text:'2 (professional)', value:'2 (professional)'},
        {id:3, text:'3 (coffee)', value:'3 (coffee)'},
        {id:4, text:'4 (acquaintance)', value:'4 (acquaintance)'},
        {id:5, text:'5 (phone call)', value:'5 (phone call)'},
        {id:6, text:'', value:''},
        {id:0, text:'-- Select Relationship --', value:null},
       
        
      ],
      optionivstrng:[
         
        {id:1, text:'$1 - $1.5 mil', value:'$1 - $1.5 mil'},
        {id:2, text:'$1.5 - $2 mil', value:'$1.5 - $2 milr'},
        {id:3, text:'$2 - $3 mil', value:'$2 - $3 mil'},
        {id:4, text:'$3 - $4 mil', value:'$3 - $4 mil'},
        {id:5, text:'$4 - $6 mil', value:'$4 - $6 mil'},
        {id:6, text:'$6 - $8 mil', value:'$6 - $8 mil'},
        {id:7, text:'$8 - $10 mil', value:'$8 - $10 mil'},
        {id:8, text:'over $10 mil', value:'over $10 mil'}, 
        {id:9, text:'', value:''},
        {id:0, text:'-- Select Investment Range --', value:null},
       
      ],
      optionmarkposi:[

        
        {id:1, text:'4 (looking to buy)', value:'4 (looking to buy)'},
        {id:2, text:'5 (hold)', value:'5 (hold)'},
        {id:3, text:'6 (consider)', value:'6 (consider)'},
        {id:4, text:'7 (see offer)', value:'7 (see offer)'},
        {id:5, text:'HKL (n)', value:'HKL (n)'},
        {id:6, text:'HKL (sold)', value:'HKL (sold)'},
        {id:7, text:'HKL (y)', value:'HKL (y)'},
        {id:8, text:'HKL19', value:'HKL19'},
        {id:9, text:'possible', value:'possible'},
        {id:10, text:'update owner', value:'update owner'},
        {id:11, text:'', value:''},
        {id:0, text:'-- Select Market Position --', value:null},
       
      ],
      optionlstrst:[
        
        {id:1, text:'Ass:hc', value:'Ass:hc'},
        {id:2, text:'Ass:hc2', value:'Ass:hc2'},
        {id:3, text:'hotel', value:'hotel'},
        {id:4, text:'industrial', value:'industrial'},
        {id:5, text:'land', value:'land'},
        {id:6, text:'multi-family/retail', value:'multi-family/retail'},
        {id:7, text:'office', value:'office'},
        {id:8, text:'retail', value:'retail'},
        {id:9, text:'', value:''},
        {id:0, text:'-- Select Last Result --', value:null},
       
      ],
      optionnxtact:[
         
        {id:1, text:'Accumulating', value:'Accumulating'},
        {id:2, text:'bc online check', value:'bc online check'},
        {id:3, text:'Big Portfolio', value:'Big Portfolio'},
        {id:4, text:'company search', value:'company search'},
        {id:5, text:'JS-dts-dt', value:'JS-dts-dt'},
        {id:6, text:'JS-wv', value:'JS-wv'},
        {id:7, text:'myLTS check', value:'myLTS check'},
        {id:8, text:'title search', value:'title search'},
        {id:9, text:'', value:''},
        {id:0, text:'-- Select Next Action --', value:null},
       
      ],


      
      newid: null,
      newcontact: {
         database: null,
         data: {
          Title1: null,
          LastName1: null,
          FirstName1: null, 
          Title2: null,
          LastName2: null,
          FirstName2: null,
          Company: null,
          Email: null,
          Telephone1: null,
          Telephone2: null,
          Telephone3: null,
          Telephone4: null,
          Telephone5: null,

          Street1: null,
          Street2: null,
          Street3: null,
          City: null,
          State: null,
          Zip: null,
          Country: null,
          
          ContactID: null,
          Key1: null, // markposi // Key1
          Key2: null, // rltnshp // Key2
          Key4: null, // ivstrng // Key4
          Key5: null, //  type // Key5
          LastResult: null, // lastrslt // LastResult
          NextAction: null,  // nextact // NextAction

          UserWhoUpdated: null
         }
         
      },

      
      pid: null,
      pidaddress: null,
      pidproperty:[],
      pidpropertyforselect:[],

    }
  },
  
  mounted() {
  
  fb.auth.onAuthStateChanged(user =>{
    if(user){
      this.currentUser=user;
      
    }
  })
  },
  watch: {
    selectedcontactid: function(value){
      if (value == '')
       this.selectedcontactidvisible = true;

      if (!this.findItemWith(value, 'value', 'this.optioncontactid')){
        this.updateDynamicOption(value, 'this.optioncontactid');
      }

    },
     selectedtype: function(value){
      if (value == '')
       this.selectedtypevisible = true;

      if (!this.findItemWith(value, 'value', 'this.optiontype')){
        this.updateDynamicOption(value, 'this.optiontype');
      }

    },
    selectedrltn: function(value){
      if (value == '')
       this.selectedrltnvisible = true;

      if (!this.findItemWith(value, 'value', 'this.optionrltn')){
        this.updateDynamicOption(value, 'this.optionrltn');
      }

    },
    selectedivstrng: function(value){
      if (value == '')
       this.selectedivstrngvisible = true;

      if (!this.findItemWith(value, 'value', 'this.optionivstrng')){
        this.updateDynamicOption(value, 'this.optionivstrng');
      }

    },
    selectedmarkposi: function(value){
      if (value == '')
       this.selectedmarkposivisible = true;

      if (!this.findItemWith(value, 'value', 'this.optionmarkposi')){
        this.updateDynamicOption(value, 'this.optionmarkposi');
      }

    },
    selectedlstrst: function(value){
      if (value == '')
       this.selectedlstrstvisible = true;

      if (!this.findItemWith(value, 'value', 'this.optionlstrst')){
        this.updateDynamicOption(value, 'this.optionlstrst');
      }

    },
    selectednxtact: function(value){
      if (value == '')
       this.selectednxtactvisible = true;

      if (!this.findItemWith(value, 'value', 'this.optionnxtact')){
        this.updateDynamicOption(value, 'this.optionnxtact');
      }

    },
  },
  methods: {
    show() {
      
      this.newid = null;
      this.newcontact.database = null;
      this.newcontact.data.Title1 = null;
      this.newcontact.data.LastName1 = null;
      this.newcontact.data.FirstName1 = null; 
      this.newcontact.data.Title2 = null;
      this.newcontact.data.LastName2 = null;
      this.newcontact.data.FirstName2 = null;
      this.newcontact.data.Company = null;
      this.newcontact.data.Email = null;
      this.newcontact.data.Telephone1 = null;
      this.newcontact.data.Telephone2 = null;
      this.newcontact.data.Telephone3 = null;
      this.newcontact.data.Telephone4 = null;
      this.newcontact.data.Telephone5 = null;

      this.newcontact.data.Street1 = null;
      this.newcontact.data.Street2 = null;
      this.newcontact.data.Street3 = null;
      this.newcontact.data.City = null;
      this.newcontact.data.State = null;
      this.newcontact.data.Zip = null;
      this.newcontact.data.Country = null;
          
      this.newcontact.data.ContactID = null;
      this.newcontact.data.Key1 = null;
      this.newcontact.data.Key2 = null;
      this.newcontact.data.Key4 = null;
      this.newcontact.data.Key5 = null;
      this.newcontact.data.LastResult = null;
      this.newcontact.data.NextAction = null;

      this.linkedtoastVisible = false;
/*
      this.selectedcontactid  = null;
      this.selectedtype = null;
      this.selectedrltn  = null;
      this.selectedmarkposi  = null;
      this.selectedivstrng  = null;
      this.selectedlstrst = null;
      this.selectednxtact = null;
      this.pid = null;
      this.pidaddress = null;
      this.pidpropert =[];
      this.pidpropertyforselect=[];
*/
 
      this.showAddModal = true;

     },
     hide(){
      this.showAddModal = false;
     },
    saveNew(){
      this.newcontact.data.ContactID= this.selectedcontactid;
      this.newcontact.data.Key1 = this.selectedmarkposi;  // Key1
      this.newcontact.data.Key2 = this.selectedrltn;  // Key2
      this.newcontact.data.Key4 = this.selectedivstrng; // Key4
      this.newcontact.data.Key5 = this.selectedtype; //Key5
      this.newcontact.data.LastResult = this.selectedlstrst;
      this.newcontact.data.NextAction = this.selectednxtact;
      this.newcontact.data.UserWhoUpdated = this.currentUser.displayName;

      try{
        const headers = {
              'Content-Type': 'application/json' 
          };
          console.log('new contact');
          console.warn(this.newcontact);
        Vue.axios.post(this.$apipath + 'contacts/', JSON.stringify(this.newcontact), {
          headers: headers})
            .then((resp)=>{
              console.log('response');
              console.warn(resp.data);
            //  console.log(resp.status);
              if (resp.status == 200)
              {
                  
                 this.newid = resp.data.insertId; 
                 this.savedtoastVisible = true; // continue ask for link
                 console.log('newid'+this.newid);
              }
              else
              {
                 this.error = resp.status + resp.statusText;
                 this.errortoastVisible = true;
                 this.newid = 0; 
                
              }
            })
            
            
      }
      catch (error){
       let errorObject=JSON.parse(JSON.stringify(error));
        console.log(errorObject);
 
      }
      
       
    },

    askforLink(ons){

    this.savedtoastVisible = false;
     ons.notification.confirm('Do you want to link property?').then((response) =>
     {
         if (response==1) // yes
         {
             this.linkdialogVisible = true;
         }
         else
         {
            this.hide();
             //router.go(-1); // Back to search
         }
     });

    },

    searchpid(){
        //// in here, Call SearchPID API with db, property.pid, property.address

        console.log('newid'+this.newid);
        console.log('database'+this.newcontact.database);
        console.log('text='+(this.pid ?? this.pidaddress));
        if (this.pid != null)
          console.log('method=all-pid')
        else
          console.log('method=address');

       
        let apistr;

        if (this.pid != null)
          apistr = 'text='+this.pid + '&'+ 'database='+this.newcontact.database + '&method=all-pid' 
        else
          apistr = 'text='+this.pidaddress + '&'+ 'database='+this.newcontact.database + '&method=address';

        console.log('apistr'+apistr);

 
        try{
         
        Vue.axios.get(this.$apipath + 'contacts/linkproperty/prop-search?'+apistr)
            .then((resp)=>{
              this.pidproperty = resp.data.slice(0, 5);

              console.log('response');
              console.warn(resp.data);
            //  console.log(resp.status);
              if (resp.status == 200)
              {
                if (resp.data.length > 0)
                {

                  this.pidpropertyforselect = this.pidproperty.map(el => ({
                  checked: true,
                  Company: el.Company,
                  FirstName1: el.FirstName1,
                  FirstName2: el.FirstName2,
                  LastName1: el.LastName1,
                  LastName2: el.LastName2,
                  LocationCity: el.LocationCity,
                  LocationCountry: el.LocationCountry,
                  LocationOriginal: el.LocationOriginal,
                  LocationState: el.LocationState,
                  LocationZip: el.LocationZip,
                  MorePIDs: el.MorePIDs,
                  PID: el.PID,
                  contact_id: el.contact_id,
                  database: el.database,
                  taxrecord_id: el.taxrecord_id

                  }))
                    window.showDialog('properties-dialog');
                }
                else
                {
                    // No property record
                }
              
              
              }
              else
              {
 
                 this.error = resp.status + resp.statusText;
                 this.errortoastVisible = true;
              }
            })
            
            
      }
      catch (error){
       let errorObject=JSON.parse(JSON.stringify(error));
        console.log(errorObject);
 
      }

        
       
    },
    linkpid(ons){
       
 
        let selectedpidproperty = Object.values(this.pidpropertyforselect).filter(item => item.checked);
        let ids = [];  
        let res = "";     

        for (const name of selectedpidproperty) {
          res =  res + name.LocationOriginal+ "<br>";
          ids.push(name.taxrecord_id);     
        }

        let linkmsg = "<b>New contact:</b>" + (this.newcontact.data.FirstName1 ?? "") + " " + 
        (this.newcontact.data.LastName1 ?? "") + " " +
        (this.newcontact.data.Company ?? "") + "<br>" + "<b>Link to property(s)</b> <br>" + res;

        if (selectedpidproperty.length > 0)
        {
            ons.notification.confirm('Are you confirm to link? <br>' + linkmsg).then((response) =>
            {
                  if (response==1)
                  {
                      window.hideDialog('properties-dialog');
                     // Link...
                      try{
                        const headers = {
                              'Content-Type': 'application/json' 
                          };
                        let newlink = {
                          database : this.newcontact.database,
                          contact_id : this.newid,
                          taxrecord_ids: ids
                        };

                        Vue.axios.put(this.$apipath + 'contacts/linkproperty/relink/', JSON.stringify(newlink), {
                          headers: headers})
                            .then((resp)=>{
                              console.log('response');
                              console.warn(resp.data);
                            //  console.log(resp.status);
                              if (resp.status == 200)
                              {  
                                
                                this.linkedtoastVisible = true;
                              }
                              else
                              {
                                this.error = resp.status + resp.statusText;
                                this.errortoastVisible = true;
                                
                              }
                            })
                            
                            
                      }
                      catch (error){
                      let errorObject=JSON.parse(JSON.stringify(error));
                        console.log(errorObject);
                
                      }

                  }
              });
        
        }
        
        
    },
    findItemWith: function (value, prop, objstr) {


      for (let item in eval(objstr)) {
        console.log('item'+objstr);
        if (eval(objstr)[item][prop] === value) {
           return eval(objstr)[item];
        }
        }

      return false;
    },
    updateDynamicOption: function(value, objstr) {
      let item = this.findItemWith(true, 'dynamic', objstr);
      if (!item) {
         eval(objstr).push({
          id: eval(objstr).length,
          text: value, /*eval(objstr).length, */
          value: value,
          dynamic: true
        });
       
      }
      item.text = value;
      item.value = value;
    }

  },
}
</script>

<style scoped>

.back-button__icon {
   
   fill: white;
}

.editrow {
  width:96%;
}

.editmr {
 width:20%
}

.editname {
  width:40%
}

.edithalfrow {
  width:45%
}

.editoption {
  width:80%
}

.fulldiv {
  width:100%;
}

#addcontact {
    position: fixed;
    top: 40px;
    bottom: 0;
    left: 0;
    right: 0;
}
</style>