import Firebase from "./firebase"
import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

import VueOnsen from 'vue-onsenui'; // This imports 'onsenui', so no need to import it separately

// Webpack CSS import
import 'onsenui/css/onsenui.css';
import 'onsenui/css/onsen-css-components.css';


Vue.config.productionTip = false
//Vue.prototype.$apipath = 'https://us-central1-terratonepull.cloudfunctions.net/'

// production
// Vue.prototype.$apipath = 'https://us-central1-terratonepull-c669d.cloudfunctions.net/'
// Vue.prototype.$sitename = ''
// sandbox
Vue.prototype.$apipath = 'https://us-central1-terratonepull.cloudfunctions.net/'
Vue.prototype.$sitename = '(Testing Site)'

Vue.use(VueOnsen); // VueOnsen set here as plugin to VUE. Done automatically if a call to window.Vue exists in the startup code.

router.beforeEach((to, from, next) => {

  if(!to.meta.protected) { //route is public, don't check for authentication
      next()
  } else {  //route is protected, if authenticated, proceed. Else, login
      Firebase.auth.onAuthStateChanged((user) => {
          if(user) {
              next()
          } else {
              router.push('/login')
          }
      })
  }
})

console.log('[i] Starting client app')

new Vue({
    
  router,
  store,
  render: h => h(App)
}).$mount('#app') 
