import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

// project nj for development

const configOptions = {
  apiKey: "AIzaSyBUKCQmgk-cBgT4cIYev3-rLuZdFee0AT8",
  authDomain: "tp-nj-4e83c.firebaseapp.com",
  databaseURL: "https://tp-nj-4e83c.firebaseio.com",
  projectId: "tp-nj-4e83c",
  storageBucket: "tp-nj-4e83c.appspot.com",
  messagingSenderId: "6772780528",
  appId: "1:6772780528:web:e9220c47279206f2b68135",
  measurementId: "G-EFQ7KNJ62J"
};


firebase.initializeApp(configOptions);

// firebase utils
const db = firebase.firestore()
const auth = firebase.auth()
const currentUser = auth.currentUser
const fbemail_ID=firebase.auth.EmailAuthProvider.PROVIDER_ID
// firebase collections
const usersCollection = db.collection('users')
const postsCollection = db.collection('posts')
const commentsCollection = db.collection('comments')
const likesCollection = db.collection('likes')


export default {
  //auth:firebase.auth(),
  db,
  auth,
  currentUser,
  usersCollection,
  postsCollection,
  commentsCollection,
  likesCollection,
  fbemail_ID
}
