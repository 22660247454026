<template>
      <div id="nav">
      <!--router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link> |
      <router-link to="/search">Search</router-link> |
      <router-link to="/logout">Logout</router-link-->

      <router-view/>
      </div>
 
</template>


<script>
 
 //import toolbar from "../src/views/Toolbar.vue";
 
 



export default {
  
    name: 'App' 
     
}
</script>
<style>

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2f5f5f;
}

#nav {
  padding: 70px;
}


#nav a {
  font-weight: bold;
  color: #2c3e50;
  text-decoration: none;
}


#nav a.router-link-exact-active {
  font-weight: bold;
  color: #6642b9;
  text-decoration: none;
}
 
</style>
