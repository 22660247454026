<template>

<v-ons-page id="addtaxrec" v-if="showAddModalTax">

  <v-ons-list >
 
    <!-- Save and Hide buttons --> 
    <v-ons-list-header>
      <v-ons-list-item>
        <div class="left">          
          <ons-button :disabled="(!(edittaxrecinfo.database && edittaxrecinfo.data.UserText06 &&
              edittaxrecinfo.data.LocationHouseNumber && edittaxrecinfo.data.LocationStreet &&
              edittaxrecinfo.data.LocationTail && edittaxrecinfo.data.LocationCity) || !(this.isEnableSaveButton)) ">
            <v-ons-icon  icon="md-save" class="list-item__icon" @click="saveNewTax()"></v-ons-icon>
          </ons-button> 
        </div>

        <div>
          <ons-button :disabled="(!(isEnableSearchButton)) ">
            <v-ons-icon icon="md-search" class="list-item__icon" @click="show(); searchdialogVisible=true" ></v-ons-icon>
          </ons-button>
        </div>

        <div class="right">
          <ons-button ><v-ons-icon  icon="md-close" class="list-item__icon" @click="savetaxrec($ons)" ></v-ons-icon> </ons-button>
        </div>
      </v-ons-list-item>
    </v-ons-list-header>


    <!-- Property Information section -->
    <v-ons-list-header>Property Information</v-ons-list-header>

    <v-ons-list-item style="width:100%">
      <div class="center">  
          Database:  <v-ons-select style="width:70%" :disabled="(!isEnableDb)" placeholder="Database" v-model="edittaxrecinfo.database">
          <option v-for="item in optiondb"  :key="item.text" :value="item.text"> {{ item.text }}</option>
          </v-ons-select>
      </div>
    </v-ons-list-item>
    <v-ons-list-item>
      <v-ons-input class="editrow" :disabled="(!isEnablePid)" placeholder="PID" float v-model="edittaxrecinfo.data.UserText06"/>
    </v-ons-list-item>
    <v-ons-list-item>
      <v-ons-input class="editrow" placeholder="More PIDs" float v-model="edittaxrecinfo.data.UserText47"/>
    </v-ons-list-item>


    <!-- Property Location section -->   
    <v-ons-list-header>Property Location</v-ons-list-header>

    <v-ons-list-item>
      <ons-row>
        <ons-col width="100%">
          <ons-row>
              <ons-col width="50%"><v-ons-input class="edithalfrow" placeholder="Apartment No." float v-model="edittaxrecinfo.data.LocationSuite"/></ons-col>
              <ons-col width="50%"><v-ons-input class="edithalfrow" placeholder="Street No." float v-model="edittaxrecinfo.data.LocationHouseNumber"/></ons-col>
          </ons-row>
          <ons-row>
              <ons-col width="50%"><v-ons-input class="edithalfrow" placeholder="Street" float v-model="edittaxrecinfo.data.LocationStreet"/></ons-col>
              <ons-col width="50%"><div> 
                <v-ons-select placeholder="Tail" v-model="edittaxrecinfo.data.LocationTail">
                <option v-for="item in optiontail"  :key="item.text" :value="item.text"> {{ item.text }}</option>
                </v-ons-select>
              </div></ons-col>
          </ons-row>
          <ons-row>
              <ons-col width="50%"><div> 
                <v-ons-select placeholder="Direction" v-model="edittaxrecinfo.data.LocationDirection">
                <option v-for="item in optiondirection"  :key="item.text" :value="item.text"> {{ item.text }}</option>
                </v-ons-select>
              </div></ons-col>
              <ons-col width="50%"><v-ons-input class="edithalfrow" placeholder="City" float v-model="edittaxrecinfo.data.LocationCity"/></ons-col>
          </ons-row>
          <ons-row>
              <ons-col width="50%"><div> 
                <v-ons-select placeholder="Province" v-model="edittaxrecinfo.data.LocationState">
                <option v-for="item in optionprovince"  :key="item.text" :value="item.text"> {{ item.text }}</option>
                </v-ons-select>
              </div></ons-col>
              <ons-col width="50%"><v-ons-input class="edithalfrow" placeholder="Postal" float v-model="edittaxrecinfo.data.LocationZip"/></ons-col>
          </ons-row>
          <ons-row>
              <ons-col width="50%"><v-ons-input :disabled="(!isEnableCountry)" placeholder="Country" float v-model="edittaxrecinfo.data.LocationCountry"/></ons-col>
          </ons-row>
        </ons-col>
      </ons-row>
    </v-ons-list-item>


    <!-- Additional Information Section -->
    <v-ons-list-header> Additional Information</v-ons-list-header>

    <v-ons-list-item>
      <v-ons-input class="edithalfrow" placeholder="Lot Size (in sqft)" float v-model="editlotsize"/>
    </v-ons-list-item>

  </v-ons-list>


  <!-- Toast for Error -->
  <v-ons-toast :visible.sync="errortoastVisibleTax" animation="ascend">
            Error {{ this.error }} 
             <button @click="errortoastVisibleTax = false">Close</button>
  </v-ons-toast>


  <!-- Toast for Created -->
  <v-ons-toast :visible.sync="createdtoastVisibleTax" animation="ascend">
            Tax Record Created!  (PID: {{ newtaxrecinfo.data.UserText06 }})
             <button @click="hide">OK</button>
  </v-ons-toast>


  <!-- Toast for Saved -->
  <v-ons-toast :visible.sync="savedtoastVisibleTax" animation="ascend">
            Tax Record Updated!  (PID: {{ edittaxrecinfo.data.UserText06 }})
             <button @click="hide">OK</button>
  </v-ons-toast>


    <!-- Search Dialog -->
        <v-ons-dialog cancelable :visible.sync="searchdialogVisible">
            <div style="text-align: center; margin-top: 60px; color: #fff;"></div>
              
            <ons-toolbar>
              <div class="center">Search Property</div>
              <div class="right"><v-ons-icon icon="md-close" class="list-item__icon"  @click="searchdialogVisible=false; hide;"></v-ons-icon></div>
            </ons-toolbar>

            <v-ons-list >
              <v-ons-list-item style="width:100%">
                  <div class="center">  
                    Database:  <v-ons-select style="width:70%"  placeholder="Database" v-model="editdatabase">
                    <option v-for="item in optiondb"  :key="item.text" :value="item.text"> {{ item.text }}</option>
                    </v-ons-select>
                  </div>
              </v-ons-list-item>
              <v-ons-list-item>
                    <v-ons-input style="width:96%" placeholder="Input PID (e.g. 111-222-333)" float v-model="editpid"/> 
              </v-ons-list-item>
                  <p style="text-align: center"> 
                    <v-ons-button  :disabled="(editpid == null || editpid.length != 11 || editdatabase == null)" 
                      @click="searchdialogVisible=false; showtaxrec(editpid, editdatabase, edittaxid)">Search</v-ons-button>
                  </p>
              </v-ons-list>
        </v-ons-dialog>

</v-ons-page>

</template>


<script>
window.fn = {};

window.showDialog = function(id) {
  
  document
    .getElementById(id)
    .show();
};

window.hideDialog = function(id) {

  document
    .getElementById(id)
    .hide();
};

import router from "../router";
import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import fb from "../firebase";

Vue.use(VueAxios, axios)


export default {
 
  data() {
    return {
      router:router,
      error : null,
      errortoastVisibleTax: false,
      createdtoastVisibleTax: false,
      savedtoastVisibleTax: false,
      searchdialogVisible: false,
      isEnableSaveButton: false,
      isEnableSearchButton: false,
      isEnableCountry: false,
      isEnablePid: false,
      isEnableDb: false,
      isEditMode: false,
      isDuplicate: false,
      showAddModalTax: false,
            
      optiondb:[
          {text:'apt'},
          {text:'commercial'},
          {text:'industrial'},
          {text:'subprimary'},
      ],

      optiondirection:[
          {text:'Direction'},
          {text:'E'},
          {text:'S'},
          {text:'W'},
          {text:'N'},
          {text:'SE'},
          {text:'SW'},
          {text:'NW'},
          {text:'NE'},
      ],

      optiontail:[
          //{text:'Tail'},
          {text:'Avenue'},
          {text:'Boulevard'},
          {text:'Close'},
          {text:'Court'},
          {text:'Cresent'},
          {text:'Drive'},
          {text:'Hwy'},
          {text:'Lane'},
          {text:'Park'},
          {text:'Place'},
          {text:'Road'},
          {text:'Square'},
          {text:'Street'},
          {text:'Walk'},
          {text:'Way'},
      ],
      
      optionprovince:[
          //{text:'Province'},
          {text:'AB'},
          {text:'BC'},
          {text:'NB'},
          {text:'NL'},
          {text:'NS'},
          {text:'MB'},
          {text:'ON'},
          {text:'PE'},
          {text:'QC'},
          {text:'SK'},    
      ],

      currentUser:{},

      defaultDirection: 'Direction',
      defaultTail: 'Avenue',
      defaultState: 'BC',
      defaultCountry: 'Canada',

      editdatabase: null,
      editpid: null,
      edittaxid: null,
      searchresult: [],
      searchtaxid: null,
      gettaxrecinfo: [],
      preveditinfo: '',
      prevedithouseno: null,
      preveditdirection: null,
      preveditstreet: null,
      prevedittail: null,

      editlotsize: null,

      newtaxid: null,
      newtaxrecinfo: {
         database: null,
         data: {
          UserText06: null,
          UserText47: null,
          LocationSuite: null,
          LocationHouseNumber: null,
          LocationDirection: null,
          LocationStreet: null,
          LocationTail: null,
          LocationCity: null, 
          LocationState: null,
          LocationZip: null,
          LocationCountry: null,
          LocationOriginal: null,
          UserText04: null,
          UserNumeric22: null,
          ContactID: null,
          DateTimeCreated: null,
          UserWhoCreated: null
         },
      },

      edittaxrecinfo: {
         database: null,
         id: null,
         data: {
          UserText06: null,
          UserText47: null,
          LocationSuite: null,
          LocationHouseNumber: null,
          LocationDirection: null,
          LocationStreet: null,
          LocationTail: null,
          LocationCity: null, 
          LocationState: null,
          LocationZip: null,
          LocationCountry: null,
          LocationOriginal: null,
          UserText04: null,
          UserNumeric22: null,
          ContactID: null,
          DateTimeUpdated: null,
          UserWhoUpdated: null
         },
      },
      
    }
  },
  
  mounted() {
  
  fb.auth.onAuthStateChanged(user =>{
    if(user){
      this.currentUser=user;
      
    }
  })
  },
  watch: { 

  },
  methods: {
    show() {
      
      this.newtaxid = null;
      this.edittaxrecinfo.database = null;
      this.edittaxrecinfo.id = null;
      this.edittaxrecinfo.data.UserText06 = null;
      this.edittaxrecinfo.data.UserText47 = null;
      this.edittaxrecinfo.data.LocationSuite = null;
      this.edittaxrecinfo.data.LocationHouseNumber = null;
      this.edittaxrecinfo.data.LocationDirection = this.defaultDirection;
      this.edittaxrecinfo.data.LocationStreet = null;
      this.edittaxrecinfo.data.LocationTail = this.defaultTail;
      this.edittaxrecinfo.data.LocationCity = null;
      this.edittaxrecinfo.data.LocationState = this.defaultState; 
      this.edittaxrecinfo.data.LocationZip = null;
      this.edittaxrecinfo.data.LocationCountry = this.defaultCountry;
      this.edittaxrecinfo.data.LocationOriginal = null;
      this.edittaxrecinfo.data.UserText04 = null;
      this.edittaxrecinfo.data.UserNumeric22 = null;
      this.edittaxrecinfo.data.ContactID = null;
      this.edittaxrecinfo.data.DateTimeUpdated = null;
      this.edittaxrecinfo.data.UserWhoUpdated = null;
      this.editlotsize = null;

      this.isEnableSaveButton = true;
      this.isEnableSearchButton = true;
      this.isEnablePid = true;
      this.isEnableDb = true;
      this.isEnableCountry = false;
      this.isEditMode = false;
      this.editdatabase = null;
      this.editpid = null;
      this.showAddModalTax = true;
    },
    hide(){
      this.showAddModalTax = false;
      this.isEnableSaveButton = false;
      this.createdtoastVisibleTax = false;
      this.savedtoastVisibleTax = false;
      this.errortoastVisibleTax = false;
    },
    validmajor(){
      // check empty of major fields
      if (this.edittaxrecinfo.database == null || this.edittaxrecinfo.data.UserText06 == null ||
          this.edittaxrecinfo.data.LocationHouseNumber == null || this.edittaxrecinfo.data.LocationStreet == null ||
          this.edittaxrecinfo.data.LocationCity == null){
            return false;
      } else if (this.edittaxrecinfo.database == '' || this.edittaxrecinfo.data.UserText06 == '' ||
          this.edittaxrecinfo.data.LocationHouseNumber == '' || this.edittaxrecinfo.data.LocationStreet == '' ||
          this.edittaxrecinfo.data.LocationCity == ''){
            return false;
      }
      return true;
    },
    validpid(apid){
      // check input pid in correct format 000-000-000
      let splitedpid = apid.split("-");
      console.log("splitedpid ="+JSON.stringify(splitedpid));

      if (splitedpid.length == 3){
          let count = 0;
          for (let i = 0; i < splitedpid.length; i++) {  
              if (splitedpid[i] >= 0 && splitedpid[i] <= 999) { count++; }
          }  
          if (count == 3){
              console.log("validpid result = true");
              return true;
          }
          else{
              this.error = "Invalid PID (Correct format: 111-222-333)";
              this.errortoastVisibleTax = true;
              console.log("validpid result 1 = false");
              return false;
          } 
      }
      else {
          console.log("validpid result 2 = false");
          this.error = "Invalid PID (Correct format: 111-222-333)";
          this.errortoastVisibleTax = true;
          return false;
      }
    },
    validzip(azip){    
      // check input zip in correct format (XXX XXX)
      let splitedzip = azip.split(" ");
      console.log("splitedzip ="+JSON.stringify(splitedzip));

      if (splitedzip.length == 2) {
          let count = 0;    
          for (let i = 0; i < splitedzip.length; i++) {  
              if (splitedzip[i].length == 3) { count++; }
          }
          if (count == 2) {
              console.log("validzip result = true");
              return true;
          }
          else{
              this.error = "Invalid Postal (use e.g. V6B 6B1)";
              this.errortoastVisibleTax = true;
              console.log("validpid result 1 = false");
              return false;
          }
      }
      else {
          console.log("validzip result 2 = false");
          this.error = "Invalid Postal (use e.g. V6B 6B1)";
          this.errortoastVisibleTax = true;
          return false;
      }
    },
    preparenew(){
      // prepare details for create new tax record
      this.newtaxrecinfo.database = this.edittaxrecinfo.database;
      this.newtaxrecinfo.data.UserText06 = this.edittaxrecinfo.data.UserText06;
      this.newtaxrecinfo.data.UserText47 = this.edittaxrecinfo.data.UserText47;
      this.newtaxrecinfo.data.LocationSuite = this.edittaxrecinfo.data.LocationSuite;
      this.newtaxrecinfo.data.LocationHouseNumber = this.edittaxrecinfo.data.LocationHouseNumber;
      
      if (this.edittaxrecinfo.data.LocationDirection == this.defaultDirection){
          this.newtaxrecinfo.data.LocationDirection = null;
      } else {
          this.newtaxrecinfo.data.LocationDirection = this.edittaxrecinfo.data.LocationDirection;
      }
      console.log("newtax location direction ="+this.newtaxrecinfo.data.LocationDirection);
      console.log("edittax location direction ="+this.edittaxrecinfo.data.LocationDirection);

      this.newtaxrecinfo.data.LocationStreet = this.edittaxrecinfo.data.LocationStreet;
      this.newtaxrecinfo.data.LocationTail = this.edittaxrecinfo.data.LocationTail;
      //if (this.edittaxrecinfo.data.LocationTail == this.defaultTail){
      //    this.newtaxrecinfo.data.LocationTail = null;
      //} else {
      //    this.newtaxrecinfo.data.LocationTail = this.edittaxrecinfo.data.LocationTail;
      //}
      //console.log("newtax location tail ="+this.newtaxrecinfo.data.LocationTail);
      
      this.newtaxrecinfo.data.LocationCity = this.edittaxrecinfo.data.LocationCity;
      this.newtaxrecinfo.data.LocationState = this.edittaxrecinfo.data.LocationState;
      //if (this.edittaxrecinfo.data.LocationState == this.defaultState){
      //    this.newtaxrecinfo.data.LocationState = null;
      //} else {
      //    this.newtaxrecinfo.data.LocationState = this.edittaxrecinfo.data.LocationState;
      //}
      //console.log("newtax location state ="+this.newtaxrecinfo.data.LocationState);
      
      this.newtaxrecinfo.data.LocationZip = this.edittaxrecinfo.data.LocationZip;
      this.newtaxrecinfo.data.LocationCountry = this.edittaxrecinfo.data.LocationCountry;

      console.log("newtax location original 1 ="+this.newtaxrecinfo.data.LocationOriginal);
      if (this.newtaxrecinfo.data.LocationDirection == null && this.newtaxrecinfo.data.LocationTail == null){

            console.log("-- check direction -- 1");
            this.newtaxrecinfo.data.LocationOriginal = this.newtaxrecinfo.data.LocationHouseNumber + ' ' +
                  this.newtaxrecinfo.data.LocationStreet;                
      } else if (this.newtaxrecinfo.data.LocationDirection == null && this.newtaxrecinfo.data.LocationTail != null){

            console.log("-- check direction -- 2");
            this.newtaxrecinfo.data.LocationOriginal = this.newtaxrecinfo.data.LocationHouseNumber + ' ' +
                  this.newtaxrecinfo.data.LocationStreet + ' ' + this.newtaxrecinfo.data.LocationTail;  
      } else if (this.newtaxrecinfo.data.LocationDirection != null && this.newtaxrecinfo.data.LocationTail == null){
            console.log("-- check direction -- 3");

      console.log("newtax location direction ="+this.newtaxrecinfo.data.LocationDirection);
      console.log("edittax location direction ="+this.edittaxrecinfo.data.LocationDirection);
            this.newtaxrecinfo.data.LocationOriginal = this.newtaxrecinfo.data.LocationHouseNumber + ' ' +
                  this.newtaxrecinfo.data.LocationDirection + ' ' + this.newtaxrecinfo.data.LocationStreet;
      } else if (this.newtaxrecinfo.data.LocationDirection != null && this.newtaxrecinfo.data.LocationTail != null){
            console.log("-- check direction -- 4");
            this.newtaxrecinfo.data.LocationOriginal = this.newtaxrecinfo.data.LocationHouseNumber + ' ' +
                  this.newtaxrecinfo.data.LocationDirection + ' ' + this.newtaxrecinfo.data.LocationStreet + ' ' +
                  this.newtaxrecinfo.data.LocationTail;
      }
      console.log("newtax location original 2 ="+this.newtaxrecinfo.data.LocationOriginal);

      if (this.edittaxrecinfo.database == "commercial"){
        this.newtaxrecinfo.data.UserNumeric22 = this.editlotsize;
      } else {
        this.newtaxrecinfo.data.UserText04 = this.editlotsize;
      }
      console.log("edittaxrecinfo database ="+this.edittaxrecinfo.database);
      console.log("editlotsize ="+this.editlotsize);
      console.log("newtaxrecinfo UserText04 ="+this.newtaxrecinfo.data.UserText04);
      console.log("newtaxrecinfo UserNumeric22 ="+this.newtaxrecinfo.data.UserNumeric22);

      this.newtaxrecinfo.data.ContactID = this.edittaxrecinfo.data.ContactID;

      // set stamp for creating new record
      this.newtaxrecinfo.data.UserWhoCreated = this.currentUser.displayName;
      //this.edittaxrecinfo.data.DateTimeCreated = new Date().toLocaleString("en-US", {timeZone: "America/Los_Angeles"});
      this.newtaxrecinfo.data.DateTimeCreated = new Date().toLocaleString();
      console.log("newtax who created ="+this.newtaxrecinfo.data.UserWhoCreated);
      console.log("newtax date time created ="+this.newtaxrecinfo.data.DateTimeCreated);

      console.log("prepare new taxrec info ="+JSON.stringify(this.newtaxrecinfo));
    },
    saveNewTax(){
      // check input is valid
      if (this.edittaxrecinfo.data.UserText06 != null){
          if (!this.validpid(this.edittaxrecinfo.data.UserText06)) { return; }
      }
      if (this.edittaxrecinfo.data.LocationZip != null){
          if (!this.validzip(this.edittaxrecinfo.data.LocationZip)) { return; }
      }
      
      //check duplicate PID before creating new tax record
      console.log("--- searchduplicate ---");
      let duplicatesearchstr = 'text=' + this.edittaxrecinfo.data.UserText06 + '&' + 'database=' + this.edittaxrecinfo.database + '&method=main-pid';
      console.log("duplicate searchstr ="+duplicatesearchstr);

      Vue.axios.get(this.$apipath + 'contacts/linkproperty/prop-search?'+duplicatesearchstr)
      .then((resp)=>{ 
          this.searchresult = resp.data;

          console.log('response');
          console.warn(resp.data);

          if (this.searchresult.length > 0){
              console.log("searchresult ="+JSON.stringify(this.searchresult));
              console.log("searchresult0 taxrecord id ="+this.searchresult[0].taxrecord_id);
              this.error = "PID " + this.edittaxrecinfo.data.UserText06 + " already exists.";
              this.errortoastVisibleTax = true;
          }
          else {
              // Create new tax record after checking the PID is not existed
              console.log("searchresult is 0");
              this.preparenew()

              try{
                  const headers = {
                      'Content-Type': 'application/json' 
                  };
                  console.log('new tax record');
                  console.log("newtaxrecinfo ="+JSON.stringify(this.newtaxrecinfo));
                  console.warn(this.newtaxrecinfo);

                  Vue.axios.post(this.$apipath + 'taxrecords/add', JSON.stringify(this.newtaxrecinfo), {headers: headers})
                      .then((resp)=>{
                        console.log('response');
                        console.warn(resp.data);
                        //  console.log(resp.status);
                        if (resp.status == 200)
                        { 
                          this.newtaxid = resp.data.insertId; 
                          this.createdtoastVisibleTax = true; 
                          this.isEnableSaveButton = false; 
                          console.log('newtaxid='+this.newtaxid);
                        }
                        else
                        {
                          this.error = resp.status + resp.statusText;
                          this.errortoastVisibleTax = true;
                        }
                      })   //then (add new tax record)
              }   //try
              catch (error){
                  let errorObject=JSON.parse(JSON.stringify(error));
                  console.log(errorObject);
              } //catch
 
          }   //else
        })  //then (duplicate search)
    },
    saveinfo(){
      console.log("--- saveinfo ---");
      console.log("edittaxrecinfo ="+JSON.stringify(this.edittaxrecinfo));

      // set stamp for updating tax record
      this.edittaxrecinfo.data.UserWhoUpdated = this.currentUser.displayName;
      //this.edittaxrecinfo.data.DateTimeCreated = new Date().toLocaleString("en-US", {timeZone: "America/Los_Angeles"});
      this.edittaxrecinfo.data.DateTimeUpdated = new Date().toLocaleString();
      console.log("edittax who updated ="+this.edittaxrecinfo.data.UserWhoUpdated);
      console.log("edittax date time updated ="+this.edittaxrecinfo.data.DateTimeUpdated);


      try{
          const headers = {
            'Content-Type': 'application/json' 
          };

          Vue.axios.post(this.$apipath + 'taxrecords/update',  JSON.stringify(this.edittaxrecinfo), {headers: headers})
            .then((resp)=>{
                console.log('response');
                console.warn(resp.data);

                if (resp.status == 200){
                    console.log("update pass");
                    this.savedtoastVisibleTax=true;
                }
                else {
                    this.error = resp.status + resp.statusText;
                    this.errortoastVisibleTax = true;
                }     
            })  //then
      }   //try
      catch (error){
       let errorObject=JSON.parse(JSON.stringify(error));
        console.log(errorObject);
      }
    },
    prepareedit(){
      if (this.edittaxrecinfo.data.LocationDirection == this.defaultDirection){
          this.edittaxrecinfo.data.LocationDirection = null;
      }
      //if (this.edittaxrecinfo.data.LocationTail == this.defaultTail){
      //    this.edittaxrecinfo.data.LocationTail = null;
      //}
      //if (this.edittaxrecinfo.data.LocationState == this.defaultState){
      //    this.edittaxrecinfo.data.LocationState = null;
      //}

      if (this.edittaxrecinfo.data.LocationHouseNumber != this.prevedithouseno || 
          this.edittaxrecinfo.data.LocationDirection != this.preveditdirection ||
          this.edittaxrecinfo.data.LocationStreet != this.preveditstreet ||
          this.edittaxrecinfo.data.LocationTail != this.prevedittail){

          console.log("edittax location original 1 ="+this.edittaxrecinfo.data.LocationOriginal);
          if (this.edittaxrecinfo.data.LocationDirection == null && this.edittaxrecinfo.data.LocationTail == null){
              console.log("-- edit direction -- 1");
              this.edittaxrecinfo.data.LocationOriginal = this.edittaxrecinfo.data.LocationHouseNumber + ' ' +
                  this.edittaxrecinfo.data.LocationStreet;                
          } else if (this.edittaxrecinfo.data.LocationDirection == null && this.edittaxrecinfo.data.LocationTail != null){
              console.log("-- edit direction -- 2");
              this.edittaxrecinfo.data.LocationOriginal = this.edittaxrecinfo.data.LocationHouseNumber + ' ' +
                  this.edittaxrecinfo.data.LocationStreet + ' ' + this.edittaxrecinfo.data.LocationTail;  
          } else if (this.edittaxrecinfo.data.LocationDirection != null && this.edittaxrecinfo.data.LocationTail == null){
              console.log("-- check direction -- 3");
              this.edittaxrecinfo.data.LocationOriginal = this.edittaxrecinfo.data.LocationHouseNumber + ' ' +
                  this.edittaxrecinfo.data.LocationDirection + ' ' + this.edittaxrecinfo.data.LocationStreet;
          } else if (this.edittaxrecinfo.data.LocationDirection != null && this.edittaxrecinfo.data.LocationTail != null){
              console.log("-- check direction -- 4");
              this.edittaxrecinfo.data.LocationOriginal = this.edittaxrecinfo.data.LocationHouseNumber + ' ' +
                  this.edittaxrecinfo.data.LocationDirection + ' ' + this.edittaxrecinfo.data.LocationStreet + ' ' +
                  this.edittaxrecinfo.data.LocationTail;
          }
          console.log("edittax location original 2 ="+this.edittaxrecinfo.data.LocationOriginal);
      }

      if (this.edittaxrecinfo.database == 'commercial'){
          if (this.edittaxrecinfo.data.UserNumeric22 != this.editlotsize){ this.edittaxrecinfo.data.UserNumeric22 = this.editlotsize; }
      } else {
          if (this.edittaxrecinfo.data.UserText04 != this.editlotsize){ this.edittaxrecinfo.data.UserText04 = this.editlotsize; }
      }
    },
    savetaxrec(ons){
      console.log("isEditMode ="+this.isEditMode);

      if (this.isEditMode == false){
          console.log("--- checkpoint 2 ---")
          this.showAddModalTax = false; return;
      }

      this.prepareedit();          
      if (this.preveditinfo == JSON.stringify(this.edittaxrecinfo)){
          console.log("--- checkpoint 3 ---")
          this.showAddModalTax = false; return;
      }

      ons.notification.confirm('Do you want to save the change?')
        .then((response) =>{
            if (response==1){ // yes
                if (!this.validmajor()){
                  ons.notification.alert('House No., Street and City cannot be empty.');
                  //this.error = "House No., Street, City cannot be empty";
                  //this.errortoastVisibleTax = true;  
                }
                else {
                  this.saveinfo();
                }
            }   //response==1
            else {
                console.log("--- checkpoint 6 ---")
                this.showAddModalTax = false;
            }
        }); //ons.notification

    },
    getinfo(ataxid, adb){
      let getstr = 'database='+ adb + '&taxrecords_id=' + ataxid;
      console.log("getstr ="+getstr);

      try {
          Vue.axios.get(this.$apipath + 'taxrecords/search?'+getstr)
            .then((resp)=>{
                this.gettaxrecinfo = resp.data;                
                console.log('response');
                console.warn(resp.data);              

                if (resp.status == 200){
                    console.log("gettaxrecinfo length ="+this.gettaxrecinfo.length);
                    console.log("gettaxrecinfo ="+JSON.stringify(this.gettaxrecinfo));
                
                    if (this.gettaxrecinfo.length > 0){
                      
                      this.edittaxrecinfo = {
                          database: this.gettaxrecinfo[0].database,
                          id: this.gettaxrecinfo[0].id,
                        data: {
                          UserText06: this.gettaxrecinfo[0].UserText06,
                          UserText47: this.gettaxrecinfo[0].UserText47,
                          LocationSuite: this.gettaxrecinfo[0].LocationSuite,
                          LocationHouseNumber: this.gettaxrecinfo[0].LocationHouseNumber,
                          LocationDirection: this.gettaxrecinfo[0].LocationDirection,
                          LocationStreet: this.gettaxrecinfo[0].LocationStreet,
                          LocationTail: this.gettaxrecinfo[0].LocationTail,
                          LocationCity: this.gettaxrecinfo[0].LocationCity,
                          LocationState: this.gettaxrecinfo[0].LocationState,
                          LocationZip: this.gettaxrecinfo[0].LocationZip,
                          LocationCountry: this.gettaxrecinfo[0].LocationCountry,
                          LocationOriginal: this.gettaxrecinfo[0].LocationOriginal,
                          UserText04: this.gettaxrecinfo[0].UserText04,
                          UserNumeric22: this.gettaxrecinfo[0].UserNumeric22,
                          ContactID: this.gettaxrecinfo[0].ContactID,
                          DateTimeUpdated: this.gettaxrecinfo[0].DateTimeUpdated,
                          UserWhoUpdated: this.gettaxrecinfo[0].UserWhoUpdated
                        },
                      };

                      this.preveditinfo = JSON.stringify(this.edittaxrecinfo);
                      console.log("preveditinfo ="+this.preveditinfo);
                      this.prevedithouseno = this.edittaxrecinfo.data.LocationHouseNumber;
                      this.preveditdirection = this.edittaxrecinfo.data.LocationDirection;
                      this.preveditstreet = this.edittaxrecinfo.data.LocationStreet;
                      this.prevedittail = this.edittaxrecinfo.data.LocationTail;
                      console.log("prevedithouseno ="+this.prevedithouseno);
                      console.log("preveditdirection ="+this.preveditdirection);
                      console.log("preveditstreet ="+this.preveditstreet);
                      console.log("prevedittail ="+this.prevedittail);

                      if (this.edittaxrecinfo.data.LocationDirection == null){ this.edittaxrecinfo.data.LocationDirection = this.defaultDirection; }
                      //if (this.edittaxrecinfo.data.LocationTail == null){ this.edittaxrecinfo.data.LocationTail = this.defaultTail; }
                      //if (this.edittaxrecinfo.data.LocationState == null){ this.edittaxrecinfo.data.LocationState = this.defaultState; }

                      if (adb == 'commercial'){ this.editlotsize = this.gettaxrecinfo[0].UserNumeric22; }
                      else { this.editlotsize = this.gettaxrecinfo[0].UserText04; }

                      this.isEditMode = true;
                      this.isEnableSaveButton = false;
                      this.isEnablePid = false;
                      this.isEnableDb = false;
                    } //if length
                }   //if 200
                else {
                    console.log("gettaxrecinfo return error");
                }

            })   //then
            
      } catch (error)
      {
          let errorObject=JSON.parse(JSON.stringify(error));
          console.log(errorObject);
      }    
    },
    searchtaxrec(apid, adatabase){
      let searchstr = 'text=' + apid + '&' + 'database=' + adatabase + '&method=main-pid';
      console.log("searchstr ="+searchstr);

      Vue.axios.get(this.$apipath + 'contacts/linkproperty/prop-search?'+searchstr)
        .then((resp)=>{ 
            this.searchresult = resp.data;

            console.log('response');
            console.warn(resp.data);

            if (this.searchresult.length > 0){
                console.log("searchresult ="+JSON.stringify(this.searchresult));
                console.log("searchresult0 taxrecord id ="+this.searchresult[0].taxrecord_id);
                this.searchtaxid = this.searchresult[0].taxrecord_id;
                console.log("searchtaxid 1="+this.searchtaxid);
                this.getinfo(this.searchtaxid, adatabase);
            }
            else {
                console.log("searchresult is empty");
                this.error = "No match for PID!";
                this.errortoastVisibleTax = true;
            }
        })  //then
    },
    showtaxrec(searchpid, searchdatabase, searchtaxid){
      console.log("searchdatabase = "+searchdatabase);
      console.log("searchpid ="+searchpid);
      console.log("searchtaxid ="+searchtaxid);
      
      if (!this.validpid(searchpid)) { return; }

      if (searchpid != null){
          // get tax record detail with pid
          this.searchtaxrec(searchpid, searchdatabase);
      } else if (searchtaxid != null){
          // get tax record detail with taxrecord_id
          this.getinfo(searchtaxid, searchdatabase);
      }
      console.log("--- checkpoint 1 ---");      
    },

  },  //method

}  //export
</script>

<style scoped>

.back-button__icon {
   
   fill: white;
}

.editrow {
  width:96%;
}

.editmr {
 width:20%
}

.editname {
  width:40%
}

.edithalfrow {
  width:50%
}

.editoption {
  width:80%
}

.fulldiv {
  width:100%;
}

#addtaxrec {
    position: fixed;
    top: 40px;
    bottom: 0;
    left: 0;
    right: 0;
}
</style>